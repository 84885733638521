import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

const MurrietaWarehouse = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Surplus Solutions Announces Major Expansion of Operations`}
          description={`Surplus Solutions Announces Major Expansion of Operations with New 37,000 Sq Ft Warehouse in Murrieta, CA`}
          />
        <div className="row column">
          <h1>Surplus Solutions Announces Major Expansion of Operations with New 37,000 Sq Ft Warehouse in Murrieta, CA</h1>
          <div className="row">
              <p>
                <strong>Woonsocket, Rhode Island, USA; 4 June, 2024</strong> – Surplus Solutions, a leader in Equipment Lifecycle Management (ELM) services, announces the opening of a new, state-of-the-art 37,000 square foot warehouse in downtown Murrieta, CA. This facility will serve as Surplus Solutions' West Coast operations base, positioned to leverage the region's thriving biotech community and robust supply chain network.
              </p>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/n_m8jXlCYHs"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen=""
                ></iframe>
              <p> 
                The Murrieta facility marks a significant expansion for Surplus Solutions, increasing the company's total operational warehouse capacity to nearly 200,000 square feet. The new site is equipped with dedicated shipping & receiving infrastructure, professional inventory studios, universal equipment utilities, and lab environments for equipment setup and testing. The facility significantly boosts Surplus Solutions’ logistics capabilities, allowing the company to satisfy the increasing demands of biotech companies in the region and beyond.
              </p>
              <p>
                "Securing a new state-of-the-art facility in Murrieta is a major step forward in our journey to becoming a world class organization,” said Joseph Reale, CEO of Surplus Solutions. “It not only reinforces our commitment to industry growth and leadership domestically, but also serves as a catalyst for our accelerated global expansion. We are excited to continue helping our clients meet their goals by maximizing the value of their surplus assets, meeting their sustainability commitments, and providing quality equipment at a reduced cost with minimal lead times.”
              </p>
              <p>
                <strong>About Surplus Solutions</strong>
              </p>
              <p>    
                Founded in 2006 and based in Woonsocket, RI, Surplus Solutions is a premier provider of Equipment Lifecycle Management (ELM) services. The company specializes in the procurement, resale, and auctioning of clinical laboratory and processing equipment, serving the biotech, pharmaceutical, and life sciences sectors. Partnering with NMS Capital, a leading private equity firm known for fostering growth and innovation, since November 2022, we have strengthened our commitment to enhancing our customers’ lab efficiency, improving financial positioning, and supporting sustainability initiatives. Our knowledgeable team, with decades of industry expertise, has conducted numerous global equipment auctions and private treaty sales. Operating from nearly 200,000 square feet of warehouse space in Rhode Island and Murrieta, CA, the company is strategically positioned to optimize services for our diverse clientele, which ranges from small businesses to Fortune 500 companies.
              </p>
              <p>
                Media contact:
              </p>
              <p>
                Amy Crosby<br/>Sr. Director of Marketing<br/>acrosby@ssllc.com
              </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default MurrietaWarehouse
